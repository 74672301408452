import React from 'react';
import PropTypes from 'prop-types';
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'; // Ensure this path is correct

const MediaEmbed = ({
    mediaType,
    mediaSrc,
    altText,
    imageClassNames,
    youtubeSourceUrl,
    vimeoSourceUrl,
    logicalName,
    ariaHidden,
}) => {
    const renderMedia = () => {
        switch (mediaType) {
            case 'youtube':
                return (
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            data-testid="youtube-video-player"
                            className="embed-responsive-item"
                            src={youtubeSourceUrl}
                            style={{ border: 0 }}
                            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={altText}
                            aria-label="YouTube video player"
                        />
                    </div>
                );
            case 'vimeo':
                return <VimeoPlayer vimeoSourceUrl={vimeoSourceUrl} logicalName={logicalName} />;
            default:
                const getAltText = (altText, ariaHidden) => {
                    if (!altText && ariaHidden) return undefined;
                    if (!altText && !ariaHidden) return '';
                    if (altText && !ariaHidden) return altText;
                    return undefined;
                };

                const getRole = (altText, ariaHidden) => {
                    return !altText && ariaHidden ? 'presentation' : undefined;
                };

                return (
                    <img
                        src={mediaSrc}
                        alt={getAltText(altText, ariaHidden)}
                        role={getRole(altText, ariaHidden)}
                        aria-hidden={ariaHidden}
                        className={imageClassNames}
                    />
                );
        }
    };

    return renderMedia();
};

MediaEmbed.propTypes = {
    mediaType: PropTypes.oneOf(['image', 'youtube', 'vimeo']).isRequired,
    mediaSrc: PropTypes.string,
    altText: PropTypes.string,
    imageClassNames: PropTypes.string,
    youtubeSourceUrl: PropTypes.string,
    vimeoSourceUrl: PropTypes.string,
    logicalName: PropTypes.string,
};

export default MediaEmbed;
